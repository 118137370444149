import React from 'react'
import BaseComponent from './BaseComponent'

export default class extends BaseComponent {

	constructor(props) {
		super(props)
		this.state = { sticky: "", anim: "", popup: false }
	}

  componentDidMount() {
  	document.addEventListener('scroll', (e) => {
      if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
          this.setState({ sticky: "sticky" })
      } else {
          this.setState({ sticky: "" })
      }
    })

    setTimeout(() => { this.setState({ anim: "anim" }) }, 400)
  }

  render() {
  	let nav, hidden
  	this.props.nav ? nav = "nav-open" : nav = "nav-closed"

    this.props.location.pathname === "/heatmapa" ? hidden = "heatmap-open" : hidden = ""

    return (
      <header className={ this.state.sticky + " " + this.state.anim + " " + nav + " " + hidden }>
        <div className="header-inner">
        	<div className="burger" onClick={ this.props.toggleNav }><div className="line"></div></div>
        	<div className="user">
            {/* <NavLink to={ "/user" }>
          		<span className="name">{ this.props.user ? this.props.user.name : null }</span>
            </NavLink>
            <NavLink to={ "/user" }>
          		<div className="avatar" style={{ backgroundImage: "url(/assets/img/user-icon.png)" }}></div>
            </NavLink> */}
        		<button className="btn btn-logout" onClick={ this.props.logout }><span>Wyloguj&nbsp;&nbsp;></span></button>
        	</div>
        </div>
      </header>
    )
  }
}
