import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'

export default class extends BaseComponent {

  constructor(props) {
  	super(props)
  	this.state = { anim: "", popup: false, expand: [] }
  }

  componentDidMount() {
  	setTimeout(() => { this.setState({ anim: "anim" }) }, 400)
  }

  render() {
  	let nav
  	this.props.nav ? nav = "side-nav open" : nav = "side-nav"

    let navItems = this.props.data.nav.map((item, i) => {
      if(item.scope) {
        if(true) {
          return (
            <li key={ i }><NavLink to={ item.route } exact={ item.route === "/" ? true : false } onClick={ this.props.hideNav }><div className="img"><img src={ item.icon } alt=""/></div>{ item.name }</NavLink></li>
          )
        } else {
          return null
        }
      } else {
        return (
          <li key={ i }><NavLink to={ item.route } exact={ item.route === "/" ? true : false } onClick={ this.props.hideNav }><div className="img"><img src={ item.icon } alt=""/></div>{ item.name }</NavLink></li>
        )
      }
    })

    return (
      <div className={ nav + " " + this.state.anim }>
	     	<div className="close" onClick={ this.props.hideNav }></div>
	    	<div className="top">
	    		<img className="side-logo" src="/assets/img/bat-logo-white.svg" alt=""/>
	    	</div>
        <nav>
          <ul className="nav-items">
            { navItems }
          </ul>
        </nav>
	    	<div className="tdy-logo"><a href="https://tdy.pl" target="_blank"><img src="/assets/img/logo.png" alt="Today. Logo"/></a></div>
      </div>
    )
  }
}
