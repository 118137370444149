import React from 'react'
import BaseComponent from './components/BaseComponent'
import { withRouter, Switch, Route } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import 'custom-event-polyfill'
import Header from './components/Header'
import ErrorPage from './components/ErrorPage'
import LoginPage from './components/LoginPage'
import HomePage from './components/HomePage'
import SideNav from './components/SideNav'
import '../scss/style.scss'
import _ from 'lodash'
import ListComplaint from './components/ListComplaint'

class MainView extends BaseComponent {

  constructor(props) {
    super(props)
    let selectedDomain = _.filter(this.props.domains, { default: true })[0]
    if (sessionStorage.getItem("domain") && sessionStorage.getItem("domain") !== null) {
      selectedDomain = JSON.parse(sessionStorage.getItem("domain"))
    } else if (selectedDomain) {
      sessionStorage.setItem("domain", JSON.stringify(selectedDomain))
    }
    this.state = { screen : { width: window.innerWidth, height: window.innerHeight }, user: this.props.user, loggedIn: this.props.loggedIn, nav: true, notifications: [], domains: this.props.domains, selectedDomain: selectedDomain }
    this._bind('loginSuccess', 'logout', 'toggleNav', 'hideNav', 'addNotify', 'closeNotify', 'checkSession', 'selectDomain')
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this))
    if (this.state.screen.width < 1020) {
      this.setState({ nav: false })
    }
    window.addEventListener("NOTIFY", (data) => { this.addNotify(data) })

    // setInterval(this.checkSession, 10000)
  }

  checkSession() {
    if (this.state.loggedIn) {
      fetch(this.props.API+"/contest/admin/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Basic " + sessionStorage.getItem('token')
        }
      })
      .then((response) => {
        if(response.status === 200) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie zalogowano." }}), true);
          return response.json()
        } else if(response.status === 401) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Niepoprawne dane."}}), true);
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
        }
      })
      .then((result) => {
        // if (result.status.success) {
        //   window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie zalogowano."}}), true);
        //   this.props.loginSuccess({})
        // } else {
        //   sessionStorage.removeItem('token')
        // }
      }, (error) => {
        console.log(error)
        // window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
      })
    }
  }

  selectDomain(item) {
    this.setState({ selectedDomain: item })
    sessionStorage.setItem("domain", JSON.stringify(item))
    this.props.history.push("/")
  }

  addNotify(e) {
    let stamp = new Date()
    stamp = stamp.getTime()
    let temp = this.state.notifications
    let item = { type: e.detail.type, content: e.detail.content, stamp: stamp }
    temp.push(item)
    this.setState({ notifications: temp })
    setTimeout(() => {
      this.closeNotify(stamp)
    }, 8000)
  }

  closeNotify(stamp) {
    let index = 999
    let temp = this.state.notifications
    for (var i = 0; i < this.state.notifications.length; i++) {
      if(this.state.notifications[i].stamp === stamp) {
        index = i
      }
    }
    temp.splice(index, 1);
    this.setState({ notifications: temp })
  }

  scrollTop() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      setTimeout(this.scrollTop, 200)
    }
  }

  handleResize(e) {
    this.setState({ screen : { width: window.innerWidth, height: window.innerHeight }, nav: window.innerWidth < 1020 ? false : true})
  }

  loginSuccess(data) {
    this.setState({ loggedIn: true, user: data })
    let redirect = "/"
    if (this.props.location.hash.indexOf("#redirect=") !== -1) {
      redirect = this.props.location.hash.replace("#redirect=", "")
    }
    this.props.history.push(redirect)
  }

  logout() {
    sessionStorage.removeItem('token')
      // sessionStorage.removeItem('domain')
    this.setState({ loggedIn: false, user: { name: "" } })
    this.props.history.push("/login")
  }

  toggleNav() {
    if (this.state.nav) {
      this.setState({ nav: false })
    } else {
      this.setState({ nav: true })
    }
  }

  hideNav() {
    if (this.state.screen.width < 1020) {
      this.setState({ nav: false })
    }
  }

  render() {
    let routes = this.props.data.pages.map((item, i) => {
      return (
          <Route key={ i+"-list" } path={ item.route } exact render={(props) => (<ListPage data={ item } API={ this.props.API } user={ this.state.user } selectedDomain={ this.state.selectedDomain } templates={ this.props.data.templates } screen={ this.state.screen } location={ this.props.location } sessionId = { this.props.sessionId } {...props}/>)}/>
      )
    })

    let routesEdit = this.props.data.pages.map((item, i) => {
      return (
          <Route key={ i+"-edit" } path={ item.editLink } exact render={(props) => (<EditPage data={ item } API={ this.props.API } user={ this.state.user } selectedDomain={ this.state.selectedDomain } templates={ this.props.data.templates } screen={ this.state.screen } location={ this.props.location } sessionId = { this.props.sessionId } {...props}/>)}/>
      )
    })

    let routesAdd = this.props.data.pages.map((item, i) => {
      return (
          <Route key={ i+"-add" } path={ item.new } exact render={(props) => (<AddPage data={ item } API={ this.props.API } user={ this.state.user } selectedDomain={ this.state.selectedDomain } templates={ this.props.data.templates } screen={ this.state.screen } location={ this.props.location } sessionId = { this.props.sessionId } {...props}/>)}/>
      )
    })

    let notifications = this.state.notifications.map((item, i) => {
      return (
        <CSSTransition key={ item.stamp } classNames="notification" timeout={{ enter: 500, exit: 500 }} >
          <div className={ "notification " + item.type } style={{ transform: "translateY("+(i*50)+"px)" }} onClick={ this.closeNotify.bind(null, item.stamp) }>
            <div className="close"></div>
            <p>{ item.content }</p>
          </div>
        </CSSTransition>
      )
    })

    return (
      <div className="app-inner">
        { this.state.loggedIn ? <Header data={ this.props.data } selectedDomain={ this.state.selectedDomain } domains={ [] } selectDomain={ this.selectDomain } location={ this.props.location } user={ this.state.user } loggedIn={ this.state.loggedIn } logout={ this.logout } toggleNav={ this.toggleNav } nav={ this.state.nav }/> : null }
        { this.state.loggedIn ? <SideNav scope={this.state.user.scope || {}} data={ this.props.data } API={ this.props.API } user={ this.state.user } loggedIn={ this.state.loggedIn } hideNav={ this.hideNav } nav={ this.state.nav } domains={ this.state.domains || [] } selectedDomain={ this.state.selectedDomain } selectDomain={ this.selectDomain }/> : null }
        <TransitionGroup className={ this.state.nav ? "page-holder open" : "page-holder" }>
          <CSSTransition key={ this.props.location.pathname } classNames="page" timeout={{ enter: 400, exit: 200 }} >
            <Switch location={ this.props.location }>
              <Route  path="/" exact render={(props) => (<HomePage data={ this.props.data } API={ this.props.API } screen={ this.state.screen } location={ this.props.location } user={ this.state.user } loggedIn={ this.state.loggedIn } sessionId = { this.props.sessionId }/>)}/>
              <Route  path="/login" exact render={(props) => (<LoginPage data={ this.props.data } API={ this.props.API } screen={ this.state.screen } location={ this.props.location } loginSuccess={ this.loginSuccess } sessionId = { this.props.sessionId }/>)}/>
              <Route path="/complaint"  exact render={(props) => (<ListComplaint API={ this.props.API } user={ this.state.user } screen={ this.state.screen } location={ this.props.location } sessionId = { this.props.sessionId }/>)}/>
              { routes }
              { routesAdd }
              { routesEdit }
              <Route render={(props) => (<ErrorPage data={ this.props.data }/>)} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
        <TransitionGroup className="notifications">
          { notifications }
        </TransitionGroup>
      </div>
    )
  }
}

export default withRouter(MainView)