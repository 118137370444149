import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import MainView from './app/MainView'

export default class Main {

	init() {
		this.user = undefined
		this.loggedIn = false
		this.sessionId = null
		this.preloadJSON("/data/data.json")
		//this.API = "https://ford-api.tdywidget.com"
		this.API = process.env.REACT_APP_API_URL
	}

	preloadJSON( dataURL) {
		fetch(dataURL)
		.then((response) => {
		    return response.json()
		})
		.then((result) => {
		    this.data = result
		    this.checkUser()
		    //this.ready()
		})
	}

	// checkUser() {
	// 	let data  = {}
	// 	fetch(this.API+"/auth/describe", {
	// 		method: "POST",
	// 		headers: {
	// 		  "Content-Type": "application/json; charset=utf-8",
	// 		  "Authorization": "Bearer " + sessionStorage.getItem('token')
	// 		},
	// 		body: JSON.stringify(data)
	// 	  })
	// 	  .then((response) => {
	// 	    return response.json()
	// 	  })
	// 	  .then((response) => {
	// 		if(response.status === 200) {
	// 		  window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie zalogowano." }}), true);
	// 		} else if(response.status === 401) {
	// 		  window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Niepoprawne dane."}}), true);
	// 		  this.loggedIn = false
	// 		this.user = { name: "" }
	// 		if (document.location.pathname === "/login") {
	// 			window.history.replaceState("index.html", "Home page", "/")
	// 		}
	// 		this.ready()
	// 		sessionStorage.removeItem('token')
	// 		} else {
	// 		  window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
	// 		  this.loggedIn = false
	// 		this.user = { name: "" }
	// 		window.history.replaceState("index.html", "Login page", "/login")
	// 		this.ready()
	// 		sessionStorage.removeItem('token')
	// 		}
	// 	  })
	// 	  .then((result) => {
	// 		if (result && result.status.success) {
	// 			this.loggedIn = true
	// 			this.user = { name: "Admin", role: "admin", scope: "winner" }
	// 			this.domains = []
	// 			if (document.location.pathname === "/login") {
	// 				window.history.replaceState("index.html", "Home page", "/")
	// 			}
	// 			this.ready()
	// 		} else {
	// 		  sessionStorage.removeItem('token')
	// 		}
	// 	  }, (error) => {
	// 		console.log(error)
	// 		// window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
	// 	  })
	// }


	checkUser() {
		let data  = {}
		fetch(this.API+"/auth/describe", {
			method: "POST",
			headers: {
		        "Content-Type": "application/json; charset=utf-8",
		        "Authorization": "Bearer " + sessionStorage.getItem('token')
		    },
			body: JSON.stringify(data)
		})
		.then((response) => {
		    return response.json()
		})
		.then((result) => {
		    if (result.status?.success === true) {
		    	this.loggedIn = true
				this.user = { name: result.data.support.firstName + " " + result.data.support.lastName, role: result.data.support.role }
				if (document.location.pathname === "/login") {
					window.history.replaceState("index.html", "Home page", "/")
				}
				this.ready()
		    } else {
		    	this.loggedIn = false
		    	this.user = { name: "" }
				window.history.replaceState("index.html", "Login page", "/login")
				this.ready()
				sessionStorage.removeItem('token')
		    }
		}, (error) => {
			console.log(error)
		})
	}

	ready() {
		this.render()
	}

	render() {
		const mainContainer = document.getElementById('app')

		ReactDOM.render(
			<Router>
				<MainView 
					user={ this.user } 
					domains={ this.domains }
					loggedIn={ this.loggedIn } 
					API = { this.API }
					sessionId = { this.sessionId }
					data={ this.data }/>
			</Router>
		, mainContainer)
	}
}

const app = new Main()
app.init()