import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate';
import xlsx from "json-as-xlsx"

const statusMap = new Map([
  ["inprogress", "W toku"],
  ["rejected", "Odrzucony"],
  ["accepted", "Zaakceptowany"],
  ["complete", "Zakończony"]
])

export default function ListComplaint(props) {
  const [list, setList] = useState([])
  const [search, setSearch] = useState("")
  const [status, setStatus] = useState("")
  const [showCurrentUser, setShowCurrentUser] = useState(false)
  const [order, setOrder] = useState("created")
  const [orderDesc, setOrderDesc] = useState("true")
  const [offset, setOffset] = useState(0)
  const [count, setCount] = useState(0)
  const [pages, setPages] = useState(0)
  const [page, setPage] = useState(0)

  const elementsOnPage = 20
  const [preloader, setPreloader] = useState(false)

  const handleSearch = useRef(_.debounce( (search, status, showCurrentUser, order, orderDesc, offset) => {
    getData(search, status, showCurrentUser, order, orderDesc, offset)
  }, 600))

  useEffect(() => {
    getData()
  }, [status, showCurrentUser, order, orderDesc, page])

  useEffect(() => {
    handleSearch.current(search, status, showCurrentUser, order, orderDesc, offset)
  }, [search])

  useEffect(() => {
    setPages(Math.ceil(count/elementsOnPage))
    setPage(0)
  }, [count])

  const getData = (sSearch, sStatus, sShowCurrentUser, sOrder, sOrderDesc, sOffset) => {
    let od = orderDesc
    if(sOrderDesc) od = sOrderDesc

    const data = {
      limit: elementsOnPage,
      showCurrentUser: sShowCurrentUser || showCurrentUser,
      offset: sOffset || offset,
      query: sSearch || search,
      status: sStatus || status,
      order: {
        field: sOrder || order,
        desc: od === "true" ? true : false
      }
    }
    
    getCount(data)
    getList(data)
  }

  const getList = (data) => {

    fetch(props.API + "/complaint/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem('token'),
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (result.status?.success) {
            setList(result.data?.complaints)
            setPreloader(false)
          } else {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': result.data.error } }), true);
            setPreloader(false)
          }
        },
        (error) => {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': "Coś poszło nie tak." } }), true);
          setPreloader(false)
          console.log(error)
        }
      )
  }

  const exportXLSX = () => {
    const data = {
      limit: count,
      showCurrentUser: showCurrentUser,
      offset: 0,
      query: search,
      status: status,
      order: {
        field: order,
        desc: orderDesc === "true" ? true : false
      }
    }

    fetch(props.API + "/complaint/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem('token'),
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (result.status?.success) {
            const list = result.data?.complaints
            let data = [
              {
                sheet: "Complaints",
                columns: [
                  { label: "ID", value: "id" },
                  { label: "E-mail", value: "emailAddress" },
                  { label: "Telefon", value: "mobilePhone" },
                  { label: "Status zgłoszenia", value: (row) => { return statusMap.get(row.status) } },
                  { label: "Powód odrzucenia", value: "rejectReason" },
                  { label: "Moderator", value: "support" },
                  { label: "Data zgłoszenia", value: (row) => { return row.created || "" } },
                  { label: "Data aktualizacji", value: (row) => { return row.updatedStatus || "" } }
                ],
                content: list,
              }
            ]
            
            let settings = {
              fileName: "Reklamacje",
              extraLength: 3,
              writeMode: "writeFile", 
              writeOptions: {}
            }

            xlsx(data, settings)

            setPreloader(false)
          } else {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': result.data.error } }), true);
            setPreloader(false)
          }
        },
        (error) => {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': "Coś poszło nie tak." } }), true);
          setPreloader(false)
          console.log(error)
        }
      )
  }

  const getCount = (data) => {
    setPreloader(true)

    fetch(props.API + "/complaint/count", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem('token'),
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (result.status?.success) {
            setCount(result.data.count)
          } else {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': result.data.error } }), true);
            setPreloader(false)
          }
        },
        (error) => {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': "Coś poszło nie tak." } }), true);
          setPreloader(false)
          console.log(error)
        }
      )
  }

  const handlePageClick = (data) => {
    let selected = data.selected
    let offset = (selected * elementsOnPage) % count;
    setOffset(offset)
    setPage(parseInt(selected))
  }

  useEffect(() => {
    getCount()
  }, [])

  const ChangeStatus = ({ uuid, status, reason }) => {
    const [selectedOption, setSelectedOption] = useState(status ?? "")
    const [rejectReason, setRejectedReason] = useState(reason || "")
    const [selectedByUser, setSelectedByUser] = useState(false)
    const [errors, setErrors] = useState({
      textarea: false,
      select: false,
    })

    const updateComplaint = (uuid, status, reason) => {
      setPreloader(true)
      const data = {
        "complaint": {
          "uuid": uuid,
          "status": status,
          "rejectReason": reason
        }
      }

      fetch(props.API + "/complaint/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem('token'),
        },
        body: JSON.stringify(data)
      })
        .then((response) => {
          return response.json()
        })
        .then(
          (result) => {
            if (result.status?.success) {
              let newList = [...list]
              const index = newList.findIndex(e => e.uuid === uuid)
              newList[index] = result.data
              setList([...newList])
              window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "green", 'content': "Pomyślnie zaktualizowano." } }), true);
            } else {
              window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': result.data.error } }), true);
            }
            setPreloader(false)
          },
          (error) => {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': "Coś poszło nie tak." } }), true);
            setPreloader(false)
            console.log(error)
          }
        )
    }

    const validate = () => {
      if (selectedOption === "") {
        setErrors(prev => ({ ...prev, select: true }))
      } else if (selectedOption === "rejected" && rejectReason.length === 0) {
        setErrors(prev => ({ ...prev, textarea: true }))
      } else {
        updateComplaint(uuid, selectedOption, rejectReason)
      }
      console.log(errors)
    }

    return (
      <div className='change-status'>
        <div className='select-wrapper'>
          <label htmlFor="change-status"><strong>Zmień status</strong></label>
          {errors.select && <p className='error'>Pole wymagane</p>}
          <div className="search-input disable-before">
            <select name="status" id="change-status"
              onChange={e => { setSelectedOption(e.target.value); setSelectedByUser(true) }}
              value={selectedOption} onBlur={e => e.target.value.length !== 0 && setErrors(prev => ({ ...prev, select: false }))}>
              <option value="">Proszę wybrać status</option>
              {Array.from(statusMap, ([name, value]) => ({ name, value })).map((e, index) => (
                <option value={e.name} key={index}>{e.value}</option>
              ))}
            </select>
          </div>
          <button onClick={validate}>
            Zmień status
          </button>
        </div>
        {selectedOption === "rejected" && <div className='textarea-wrapper'>
          <label htmlFor="change-status">Powód odrzucenia</label>
          {errors.textarea && <p className='error'>Pole wymagane</p>}
          <textarea name="reject-reason" id="" cols="32" rows="13" maxLength={600}
            value={rejectReason} onChange={e => { setRejectedReason(e.target.value) }}
            onBlur={e => e.target.value.length !== 0 && setErrors(prev => ({ ...prev, textarea: false }))}
            style={errors.textarea ? { borderColor: "red" } : {}} />
        </div>}
      </div>
    )
  }

  // console.log(showCurrentUser)

  return (
    <section className='list complaint'>
      <div className="container">
        <h1>Reklamacje</h1>
        <div className="upper-settings">
          <div className="search-box-complaint">
            <label htmlFor="search"><strong>Szukaj</strong> <small>(adres e-mail, id)</small></label>
            <input id="search" value={search} type="text" onChange={(e) => { setSearch(e.target.value) }} />
            {/* <button onClick={() => { getCount({ query: search, limit: elementsOnPage.current }); page = 0 }}>Szukaj</button> */}
            <label htmlFor="elements-on-page" style={{marginTop: "20px"}}><strong>Szukaj po statusie</strong></label>
            <div className="search-input disable-before">
              <select name="status" id="elements-on-page"
                onChange={(e) => {
                  setStatus(e.target.value)
                }}
                value={status}>
                <option value={""}>Wybierz status</option>
                {/* {console.log(statusMap.entries)} */}
                {Array.from(statusMap, ([name, value]) => ({ name, value })).map((e, index) => (
                  <option value={e.name} key={index}>{e.value}</option>
                ))}
              </select>
            </div>
            <div className='checkbox'>
              <input type="checkbox" id="currentUser" checked={showCurrentUser} value={showCurrentUser} onChange={(e) => { setShowCurrentUser(!showCurrentUser) }}/>
              <label htmlFor='currentUser'>Pokaż tylko moje zgłoszenia</label>
            </div>
          </div>

          <div className="elements-on-page">
            <div className="search-input disable-before">
              <label htmlFor="elements-on-page" style={{marginTop: "20px"}}><strong>Sortuj wg: </strong></label>
              <select name="order" id="elements-on-page"
                style={{width: "100%", marginTop: "12px"}}
                onChange={(e) => {
                  setOrder(e.target.value)
                }}
                value={order}>
                <option value={"created"}>Data utworzenia</option>
                <option value={"updated"}>Data aktualizacji</option>
              </select>
              <select name="order"
                style={{width: "100%", marginTop: "12px"}}
                onChange={(e) => {
                  setOrderDesc(e.target.value)
                }}
                value={orderDesc}>
                <option value={false}>Rosnąco</option>
                <option value={true}>Malejąco</option>
              </select>
              <div className='btn-holder'>
                <div className="btn" style={{ fontSize: "14px", textTransform: "initial", cursor: "pointer" }} onClick={() => {
                  exportXLSX()
                }}><span>Eksportuj wybrane</span></div>
              </div>
            </div>
          </div>

          {/* <div className="elements-on-page">
            <label htmlFor="elements-on-page"><strong>Ilość Reklamacji na stronę</strong></label>
            <div className="search-input disable-before">
              <select name="status" id="elements-on-page"
                onChange={e => { getCount({ limit: +e.target.value, query: search }); elementsOnPage.current = +e.target.value; page = 0 }}
                value={elementsOnPage.current}>
                <option value={1}>10</option>
                <option value={3}>5</option>
                <option value={6}>60</option>
                <option value={10}>100</option>
              </select>
            </div>
          </div> */}
          {pages > 0 ? <ReactPaginate
            key={page+"-paginate"}
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(count/elementsOnPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={ (e) => { handlePageClick(e) }}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            forcePage={page}
          /> : null}
        </div>
        <ul className="list-items complaint-wrapper">
          {!preloader ?
            list.length !== 0 ? list.map((e, index) => (
              <li className="complaint-element" key={index}>
                <div className="inner">
                  <div><strong>ID:</strong> {e?.id}</div>
                  <div><strong>UUID:</strong> {e?.uuid}</div>
                  <div><strong>Data zgłoszenia: </strong>{new Date(e?.created).toLocaleDateString('pl-PL')} {new Date(e?.created).toLocaleTimeString('pl-PL')}</div>
                  <div><strong>Adres e-mail:</strong> {e?.emailAddress}</div>
                  <div><strong>Nr telefonu:</strong> {e?.mobilePhone}</div>
                  <div><strong>Status zgłoszenia:</strong> {statusMap.get(e?.status)}</div>
                  <div><strong>Ostatnia zmiana statusu: </strong>{e?.updatedStatus ? `${new Date(e?.updatedStatus).toLocaleDateString("pl-PL")} ${new Date(e?.updatedStatus).toLocaleTimeString("pl-PL")}` : ""}</div>
                  <div><strong>Status zmienony przez: </strong>{e?.support}</div>
                </div>
                <ChangeStatus uuid={e.uuid} status={e.status} reason={e.rejectReason} />
              </li>
            )) : <h5>Brak wyników!</h5>
            :
            <div className="preloader"><span></span></div>}
        </ul>
        {pages > 0 ? <ReactPaginate
          key={page+"-paginate"}
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(count/elementsOnPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={ (e) => { handlePageClick(e) }}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          forcePage={page}
        /> : null}
      </div>
    </section>
  )
}
